import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import axios from 'axios';
// import { format, formatISO } from 'date-fns';

export class PodcastBlock extends Component {
  static propTypes = {
    details: PropTypes.object.isRequired
  }

  componentDidMount() {
  }

  render() {
    const site = process.env.REACT_APP_COCKPITURL;
    const details = this.props.details;
    console.dir(details);

    return (
      <>
        <div className="podcastBlock col-3">
          <div className="podcastContent">
            <div className="episodeNum">Episode { details.episodeNum.padStart(3,'0') }</div>
            <div className="details">
              <p className="location">{ details.location }</p>
              <h3>{ details.title }</h3>
              <p className="description">{ details.description.split(".")[0] }.</p>
            </div>
          </div>
          <div className="podcastImage" style={{ backgroundImage: `url('${site}/storage/uploads${details.webImage.path}')` }} />
          <div className="vail" />
        </div>
      </>
    );
  }
}

export default PodcastBlock;