import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components

class Page extends Component {
  state = {
    isLoaded: false
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.querySelector('#podcastPlayer').remove();
    document.querySelector('header').remove();
    document.querySelector('footer').remove();
    document.querySelector('.size').remove();
  }

  render() {
    // const { isLoaded } = this.state;
    // console.dir(isLoaded);

    return (
      <>
        <section className="static">
          <h1><span id="offset">Barely</span> Depart<span id="offset">ed</span></h1>
        </section>
      </>
    );
  }
}

export default Page;