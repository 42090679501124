import React, { Component } from 'react';
// import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { format, formatISO } from 'date-fns';

import PodcastBlock from '../components/PodcastBlock';

export class PodcastList extends Component  {
  state = { isLoaded: false, podcasts: [], years: [] }

  componentDidMount() {
    let self = this;
    const api = process.env.REACT_APP_COCKPITURL + process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;
    const today = formatISO(new Date());

    const params = {
      limit: 5,
      sort: { pubDate: -1 },
      filter: { pubDate: {$lt: today} }
    }

    const getPodcasts = axios.get(`${api}/episode?api_key=${token}`, { params });
    axios.all([getPodcasts])
    .then((response) => {
      console.dir(response[0].data);
      let allPodcasts = response[0].data;
      const years = [];

      allPodcasts.forEach(element => {
        const pubYear = format(new Date(element.pubDate), 'yyyy');
        if (years.indexOf(pubYear) === -1) { years.push(pubYear) };
      });

      self.setState({
        podcasts: allPodcasts,
        years: years,
        isLoaded: true
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  // playEpisode = (e) => {
  //   console.dir(e.target);
  // }

  // playVideo = (e) => {
  // }

  // filterYear = (e) => {
  //   const year = e.target.innerHTML;
  //   console.dir(year);
  // }

  // range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  render() {
    const { isLoaded, podcasts } = this.state;

    return (
      <>
        <div className="container podcastList">
          <div className="row">
            <div className="col-12">
              <h2>Latest Episodes</h2>
            </div>
            {/* <div className="col-9">
              <div className="filters">
                {years.map((y, index) =>
                  <button key={ index } onClick={ this.filterYear }>{ y }</button>
                )}
              </div>
            </div> */}
          </div>

          <div className="row latestPodcasts">
            {(isLoaded) && (
              <>
                {podcasts.map((podcast, index) =>
                  <PodcastBlock details={ podcast } key={ index } />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default PodcastList;