import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components

export class Header extends Component  {
  state = {}

  componentDidMount() {
  }

  topOfContent = () => {
    let form = document.querySelector('#main');
    const y = form.offsetTop - 25;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    })
  }

  render() {
    // const { mobileMenu } = this.state;
    // console.dir(this.state);

    return (
      <>
        <footer>
        </footer>

        <div className="size">
            <div className="mobile">MOBILE - Default</div>
            <div className="tablet">TABLET - R2 Small</div>
            <div className="desktop">DESKTOP - R2 Medium</div>
        </div>
      </>
    );
  }
}

export default Header;