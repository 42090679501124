import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components
import PodcastPlayer from './PodcastPlayer';

export class Header extends Component  {
  state = { }

  componentDidMount() {
  }

  topOfContent = () => {
    let form = document.querySelector('#main');
    const y = form.offsetTop - 25;

    window.scrollTo({
      top: y,
      behavior: 'smooth'
    })
  }

  render() {
    // const { mobileMenu } = this.state;
    // console.dir(this.state);

    return (
      <>
        <button onClick={this.topOfContent} className="screenReaderShortcut">Skip to main content</button>
        <PodcastPlayer />

        <header>
          <div className="container">
            <div className="row">
              <div className="col-4 branding">
                <p><span id="offset">Barely</span> Depart<span id="offset">ed</span></p>

                <div className="hamburger">
                  <span>Menu</span>
                </div>
              </div>

              <div className="col-8 navigation">
                <ul>
                  <li><a href="/home">Episodes</a></li>
                  <li><a href="/home">Our Story</a></li>
                  <li><a href="/home">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;