import React, { Component } from 'react';
// import { BrowserRouter, Link } from 'react-router-dom';
// import axios from 'axios';

// components
import PodcastList from '../components/PodcastList';
// import Hero from '../components/Hero';

class Page extends Component {
  state = {
    isLoaded: false
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // document.querySelector('#main').classList.add('hero');
  }

  render() {
    // const { isLoaded } = this.state;
    // console.dir(isLoaded);

    return (
      <>
        <PodcastList limit='8' />
      </>
    );
  }
}

export default Page;