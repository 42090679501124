import React, { Component } from 'react';
// import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { formatISO } from 'date-fns';

export class PodcastPlayer extends Component  {
  state = { isLoaded: false, podcast: [] }

  componentDidMount() {
    let self = this;
    const api = process.env.REACT_APP_COCKPITURL + process.env.REACT_APP_COCKPITAPI;
    const token = process.env.REACT_APP_COCKPITTOKEN;
    const today = formatISO(new Date());

    const params = {
      limit: 1,
      sort: { pubDate: -1 },
      filter: { pubDate: { $lt: today } }
    }

    const getPodcasts = axios.get(`${api}/episode?api_key=${token}`, { params });
    axios.all([getPodcasts])
    .then(function (response) {
      console.dir(response);
      self.setState({
        podcast: response[0].data[0],
        isLoaded: true
      })

      let titleWidth = document.querySelector('.playerCurrent p');
      let titleCont = document.querySelector('.playerCurrent div');

      if (titleWidth.scrollWidth > titleCont.offsetWidth) {
        titleWidth.classList.add('scroll-text');
        titleWidth.parentElement.setAttribute('style', 'flex-grow:1;');
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  playpause = (e, audioPlayer = document.querySelector('#podcastPlayer audio')) => {
    let btn = e.target;
    btn.classList.toggle('fa-play');
    btn.classList.toggle('fa-pause');

    if (!audioPlayer.paused) {
      audioPlayer.pause();
    } else {
      audioPlayer.play();
    }
  }

  rewind = (e, audioPlayer = document.querySelector('#podcastPlayer audio')) => { audioPlayer.currentTime -= 10; }
  fastforward = (e, audioPlayer = document.querySelector('#podcastPlayer audio')) => { audioPlayer.currentTime += 10; }

  volumeEnter = () => { console.log('volume on'); }
  volumeLeave = () => { console.log('volume leave'); }

  timeUpdate = (e, audioPlayer = document.querySelector('#podcastPlayer audio')) => {
    const progress = ((audioPlayer.currentTime / audioPlayer.duration) * 100);

    const hh = Math.floor(audioPlayer.currentTime / 3600).toString().padStart(2,'0');
    const mm = Math.floor((audioPlayer.currentTime % 3600) / 60).toString().padStart(2, '0');
    const ss = Math.floor(audioPlayer.currentTime % 60).toString().padStart(2, '0');

    document.querySelector('.progress').setAttribute('style', `width: ${progress}%;`);
    document.querySelector('.timestamp span').textContent = `${hh}:${mm}:${ss}`;
  }

  render() {
    const { isLoaded, podcast } = this.state;
    const site = process.env.REACT_APP_COCKPITURL;
    // console.dir(podcast);

    return (
      <>
        <div id="podcastPlayer">
          <div className="container">
            <div className="row">
              {/* Episode Name */}
              {(isLoaded) && ( <>
                <div className="col-3 playerCurrent">
                  <i className="fa-solid fa-ghost"></i>
                  <div><p>Episode { podcast.episodeNum.padStart(3, '0') } - { podcast.title }</p></div>
                </div>

                {/* Controls */}
                <div className="col-6 playerControls">
                  <p className="timestamp"><span>00:00:00</span> / { podcast.duration }</p>
                  <div className="progressBar">
                    <div className="progress"></div>
                  </div>
                  <div className="playPause">
                    <i className="fa-solid fa-backward" onClick={ this.rewind }></i>
                    <i className="fa-solid fa-play" onClick={ this.playpause }></i>
                    <i className="fa-solid fa-forward" onClick={ this.fastforward }></i>
                    <i className="fa-solid fa-volume-high" onMouseEnter={ this.volumeEnter } onMouseLeave={ this.volumeLeave }></i>
                  </div>

                  <audio onTimeUpdate={ this.timeUpdate }>
                    <source src={site + '/storage/uploads/' + podcast.audioFile.path} type="audio/mpeg" />
                  </audio>
                </div>
              </> )}

              {/* Social Icons */}
              <div className="col-3 socialSharing">
                <p>Share</p>
                <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook"></i></a>
                <a href="https://twitter.com/barelydeparted" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter"></i></a>
                <a href="https://www.instagram.com/barelydeparted/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PodcastPlayer;