import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom'

// pages
import Homepage from './Homepage';
import Static from './Static';

function Main() {
  return (

    <section id="main">
      <BrowserRouter>
        <Routes>
            <Route exact path="/" element={ <Static /> } />
            <Route exact path="/home" element={ <Homepage /> } />
         </Routes>
      </BrowserRouter>
    </section>

  );
}

export default Main;